import React, { Component } from "react";

class Header extends Component {

    constructor() {
        super();
        this.state = {}
    }

    //Metode som håndterer klokken
    clock() {
        let date = new Date();
        let hours = date.getHours().toString();
        let minutes = date.getMinutes().toString();
        if (minutes.length === 1) {
            minutes = `0${minutes}`;
        }
        if (hours.length === 1) {
            hours = `0${hours}`;
        }
        this.setState({ clock: `${hours}:${minutes}` });
        //Setter timeout som trigges når det er "nytt" minutt  
        setTimeout(() => { this.clock() }, (60 - new Date().getSeconds()) * 1000);
    };

    componentDidMount = () => {
        this.clock();
    };

    render() {

        return (
            <div className="container">
                <div className="flex-container heading">
                    <div className="titleLine">Avganger <span className="txt-sec">Departures</span></div>
                    <div>{this.state.clock}</div>
                </div>
                <div className="subHeading txt-sec">{this.props.location}</div>
            </div>
        );
    }
}

export default Header;
