import React, { Component } from "react";
class TableRow extends Component {

    render() {
        let content = <tr></tr>;
        if (this.props.data) {
            let route = this.props.data
            content = (
                <tr>
                    <td>{route.routeTime}</td>
                    <td>
                        <span 
                        className="badge" 
                        style={{ backgroundColor: `#${route.badge}`, color: `#${route.badgeText}` }}>
                        {route.routeNumber}</span>
                        {route.destination}
                    </td>
                    <td>{route.predictedTime}</td>
                    <td><span className={route.dotClass}>●</span>{route.infoTxt}</td>
                </tr>
            )
        }

        return content;
    }
}

export default TableRow;
