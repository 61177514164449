import React, { Component } from "react";
import Header from "./Components/Header";
import Table from "./Components/Table";
import "./css/App.css";

class App extends Component {
  constructor() {
    super();
    this.state = {}
  }

  //Metode for å sette state.location "passes" til table-komponenten 
  //for å kunne oppdatere header-komponentens location prop
  setLocation = (location) => {
    this.setState({ location: location });
  };

  render() {
    return (
      <div id="app">
        <Header location={this.state.location} />
        <Table setLocation={this.setLocation} />
      </div>
    );
  }
}

export default App;
