import React, { Component } from "react";
import TableRow from "./TableRow";
import { EnturRequest, BusRoute } from "../Classes/Entur";
import "../css/Table.css";

class Table extends Component {
    //Variabler for å håndtere "request-køen"
    reqQue = [];
    readyForReq = true;

    constructor() {
        super();
        //Legger til mulighet for å bestemme annen location enn
        //jernbanetorget ved å legge til location og id i url queryen.
        //F.eks. ?location=4029 for Stortinget
        let location = new URLSearchParams(window.location.search);
        location = location.get('location');
        if(!location){
            location = '4000';
        }
        this.state = {location:location}; 
    }

    componentDidMount() {
        this.sendRequest(this.state.location);
    }

    //Metode for  å sende request/kø-håndtering del 1
    sendRequest(location) {
        //Legger til object av EnturRequest-klassen til kø-arrayet
        this.reqQue.push(new EnturRequest(location));
        //Sjekker om det er en request som kjører
        if (this.readyForReq) {
            //Setter readyForReq til false for å indikere at en request kjører
            this.readyForReq = false;
            //Kaller det siste objektet i kø-arrayens sendReq metode
            //Callback og kontekst som parametere
            this.reqQue.pop().sendReq().then(res => res.json())
            //Om vellykket sendes responsen til handleResponse
            .then(res => {
                this.handleResponse(res.data);
            })
            .catch(error => {
                console.log(error);
                this.readyForReq = true;
            });
            this.reqQue = [];
        }
    }

    //Callback for request-klassen sin sendReq metode/kø-håndtering del 2
    //Respons og context som parametere.
    //Avhengig av å benytte context istedet for this her siden this
    //endres når metoden kjøres fra EnturRequest-klassen
    handleResponse(response) {
        if (response) {
            console.log(response);
            let routes = response.stopPlace.estimatedCalls;
            let busRoutes = routes.map(route =>
                new BusRoute(
                    route.destinationDisplay.frontText,
                    route.serviceJourney.journeyPattern.line.publicCode,
                    route.aimedArrivalTime,
                    route.expectedArrivalTime,
                    route.serviceJourney.journeyPattern.line.presentation.colour,
                    route.serviceJourney.journeyPattern.line.presentation.textColour
                ).generateElementJSON()
            );
            //Kaller app.js sin setLocation metode for å oppdatere lokasjonsnavn i Header-componenten 
            this.props.setLocation(response.stopPlace.name);
            this.setState({ busRoutes: busRoutes });
        }
        //Setter readyForReq for å tillate at ny request sendes siden denne requesten er ferdig
        this.readyForReq = true;
        //Kaller sendRequest metoden på nytt etter 30 sekunder for å  oppdatere data.
        setTimeout(() => {
            this.sendRequest(this.state.location);
        }, 30000);
    }

    render() {
        let content = <tr></tr>;
        //Mapper ut elementer dersom data fra request eksisterer
        if (this.state.busRoutes) {
            content = this.state.busRoutes.map((route, i) =>
                <TableRow key={`row${i}`} data={route} />
            );
        }
    
        return (
            <div className="container">
                <table>
                    <thead>
                        <tr>
                            <th>Avgang<br /><span className="txt-sec">Departure</span></th>
                            <th>Destinasjon<br /><span className="txt-sec">Destination</span></th>
                            <th>Forventet<br /><span className="txt-sec">Expected</span></th>
                            <th>Informasjon<br /><span className="txt-sec">Information</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {content}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Table;
