let EnturRequest = class {
	time = new Date().toISOString();
	constructor(location) {
		this.location = location;
	} /*
		fetch("https://api.entur.org/journeyplanner/v2/index/graphql", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"ET-Client-Name": "test"
			},
			body: JSON.stringify({
				query: `{
                    stopPlace(id: "NSR:StopPlace:${this.location}") {
                      id
                      name
                      estimatedCalls(startTime:"${this.time}" timeRange: 72100, numberOfDepartures: 10, omitNonBoarding: true) {     
                        realtime
                        aimedArrivalTime
                        expectedArrivalTime
                        forBoarding
                        destinationDisplay {
                          frontText
                        }
                        serviceJourney {
                          journeyPattern {
                            line {
                              presentation{
                                colour
                                textColour
                              }
                              publicCode
                            }
                          }
                        }
                      }
                    }
                  }`
			})
		})
			.then(res => res.json())
			//Om vellykket sendes responsen og contexten tilbake til callbackfunksjonen som i
			//dette tilfellet er handleRequest i table-componenten
			.then(res => callback(res.data, context))
			//Om det skjer noe feil prøver den å sende på ny en gang. Om det fortsatt ikke går
			//logger den error-informasjonen.
			.catch(error => retry ? console.log(error) : this.sendReq(callback, context, true));
	}
};
*/ //Metode som håndterer request v2

	//Metode som håndterer request v1
	/*
	sendReq(callback, context, retry) {
	
		**Fetch er den samme som i eksempelet på oppgaven men har lagt til:
		**forBoarding som forteller om bussen tillater ombordstigning,
		**publicCode som er rutenr på bussen og
		**alle elementer fra presentation som er fargene som brukes av rutene.
		**Jeg har også endret den sånn at den bruker tiden som var når dette
		**objektet ble opprettet som utgangspunkt mtp. når den ser etter busser
		*/ sendReq() {
		/*
		 **Fetch er den samme som i eksempelet på oppgaven men har lagt til:
		 **forBoarding som forteller om bussen tillater ombordstigning,
		 **publicCode som er rutenr på bussen og
		 **alle elementer fra presentation som er fargene som brukes av rutene.
		 **Jeg har også endret den sånn at den bruker tiden som var når dette
		 **objektet ble opprettet som utgangspunkt mtp. når den ser etter busser
		 */
		return fetch("https://api.entur.io/journey-planner/v2/graphql", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"ET-Client-Name": "test"
			},
			body: JSON.stringify({
				query: `{
				stopPlace(id: "NSR:StopPlace:${this.location}") {
				  id
				  name
				  estimatedCalls(startTime:"${this.time}" timeRange: 72100, numberOfDepartures: 10, omitNonBoarding: true) {     
					realtime
					aimedArrivalTime
					expectedArrivalTime
					forBoarding
					destinationDisplay {
					  frontText
					}
					serviceJourney {
					  journeyPattern {
						line {
						  presentation{
							colour
							textColour
						  }
						  publicCode
						}
					  }
					}
				  }
				}
			  }`
			})
		});
	}
};

let BusRoute = class {
	constructor(
		destination,
		routeNumber,
		routeTime,
		predictedTime,
		badge,
		badgeText
	) {
		this.destination = destination;
		this.routeNumber = routeNumber;
		this.routeTime = new Date(routeTime);
		this.predictedTime = new Date(predictedTime);
		this.badge = badge;
		this.badgeText = badgeText;
	}

	//Returnerer differansen på tiden i minutter
	compareTime() {
		return (this.predictedTime - this.routeTime) / 1000 / 60;
	}

	//Sjekker forskjellen mellom tidene.
	checkDifference() {
		let difference = this.compareTime();
		//State  fortelleer om bussen er i tide, forsinket eller tidlig.
		let state = 1;
		//Om differansen er negativ er bussen tidlig ute.
		if (difference < 0) {
			difference = Math.abs(difference);
			state = 2;
		}
		//Om differanseen er mindre enn et minutt er bussen i rute
		if (difference < 1) {
			difference = 0;
			state = 0;
		}
		difference = Math.floor(difference);
		return { minutes: difference, state: state };
	}

	//Lager JSON med data som kreves for å lage element
	generateElementJSON() {
		let element = {};
		element.destination = this.destination;
		element.routeNumber = this.routeNumber;
		element.routeTime = humanTime(this.routeTime);
		element.predictedTime = humanTime(this.predictedTime);
		element.badge = this.badge;
		element.badgeText = this.badgeText;

		let timeState = this.checkDifference();
		switch (timeState.state) {
			case 1:
				element.infoTxt = `${timeState.minutes} minutt forsinket`;
				element.dotClass = "red-dot";
				break;
			case 2:
				element.infoTxt = `${timeState.minutes} minutt før tiden`;
				element.dotClass = "green-dot";
				break;
			default:
				element.infoTxt = "I rute";
				element.dotClass = "gray-dot";
				break;
		}

		return element;
		//Funksjon som gjør om tid til "klokkeformat"
		function humanTime(date) {
			let hours = date.getHours().toString();
			let minutes = date.getMinutes().toString();
			if (minutes.length === 1) {
				minutes = `0${minutes}`;
			}
			if (hours.length === 1) {
				hours = `0${hours}`;
			}
			return `${hours}:${minutes}`;
		}
	}
};

export { EnturRequest, BusRoute };
